<template lang="pug">
  .plan-list
    TopBar(
      :filters="filters"
      :otas="allowedOtas"
      :shops="shops"
      :shops-loading="shopsLoading"
      :car-classes="carClasses"
      :car-class-loadin="carClassesLoading"
      @select-otas="handleSelectOtas"
      @select-shop="handleSelectShop"
      @select-car-classes="handleSelectCarClasses"
      @change-filters="applyFilters"
      @clear-filters="resetFilters"
    )
    .d-flex.justify-content-end.p-t-17.p-b-17
      AppIconButton.get-data-from-ota(
        title="plan_management.actions.get_data_from_ota"
        @click=""
        icon="reply"
        :use-app-icon="true"
        v-b-tooltip.hover="$t('inventory_management.tooltip')"
      )
      AppSaveButton.m-l-10(
        disabled
        short
        title="plan_management.actions.save_changes"
        @save=""
      )
    PlansTable(
      :plans="plans"
    )
</template>

<script>
  import planListModule from "@/config/store/plan_management/plan_list"
  import shopsModule from "@/config/store/matching/shop"
  import carClassesModule from "@/config/store/matching/car_class"
  import withStoreModule from "@/mixins/withStoreModule"
  import withAllowedOtaList from "@/mixins/withAllowedOtaList"

  const planListMixin = withStoreModule(planListModule, {
    name: "planList",
    readers: {
      filters: "filters",
      plans: "items"
    },
    mutations: {
      addItem: "ADD_ITEM",
      updateItemByIndex: "UPDATE_ITEM_BY_INDEX",
      updateAddedItemByIndex: "UPDATE_ADDED_ITEM_BY_INDEX",
      setFilters: "SET_FILTERS",
      resetFilters: "RESET_FILTERS"
    }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shops",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "carClasses",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      PlansTable: () => import("./PlansTable"),
      TopBar: () => import("./TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [planListMixin, shopsMixin, carClassesMixin, withAllowedOtaList],

    methods: {
      handleSelectOtas(otas) {
        this.applyFilters({ ...this.filters, otas, shop: undefined, carClasses: [] })
        this.fetchShops({ filters: this.filters, pagination: { _disabled: true } })
      },

      handleSelectShop(shop) {
        this.applyFilters({ ...this.filters, shop, carClasses: [] })
        this.fetchCarClasses({ filters: this.filters, pagination: { _disabled: true } })
      },

      handleSelectCarClasses(carClasses) {
        this.applyFilters({ ...this.filters, carClasses })
      },

      applyFilters(filters) {
        this.setFilters(filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  .plan-list
    .get-data-from-ota
      background-color: $default-purple
      ::v-deep
        .title
          color: $default-white
</style>
