export default [
  {
    status: true,
    ota_id: 2,
    name:
      "お得な秋プラン！１ヶ月限定のお得なレンタカーで2022年の紅葉狩りを楽しもう♪【10/1 〜 10/31 限定☆】この欄を全て埋めると258文字（スペース込み）入力できます。",
    insurance: "Included",
    sale_start_date: new Date(),
    sale_end_date: new Date(),
    posting_start_date: new Date(),
    posting_end_date: new Date(),
    car_classes: [
      {
        name: "Kクラス禁煙",
        price_items: [
          {
            id: 1,
            base_rate_period_group_name: "Base rate period group 1",
            prices: [
              { timeSlot: { type: "daily", value: "same_day" }, value: 5000 },
              { timeSlot: { type: "daily", value: "1_night_2_days" }, value: 8000 },
              { timeSlot: { type: "daily", value: "2_nights_3_days" }, value: 16000 },
              { timeSlot: { type: "daily", value: "3_night_4_days" }, value: 1000 },
              { timeSlot: { type: "daily", value: "daily_thereafter" }, value: 16000 }
            ]
          },
          {
            id: 2,
            base_rate_period_group_name: "Base rate period group 1",
            prices: [
              { timeSlot: { type: "hourly", value: "1_6_hours" }, value: 5000 },
              { timeSlot: { type: "hourly", value: "7_12_hours" }, value: 8000 },
              { timeSlot: { type: "hourly", value: "13_24_hours" }, value: 16000 },
              { timeSlot: { type: "hourly", value: "hourly_thereafter" }, value: 1000 },
              { timeSlot: { type: "hourly", value: "daily_thereafter" }, value: 16000 }
            ]
          }
        ]
      }
    ]
  },
  {
    status: false,
    ota_id: 2,
    name: "Rakuten disabled plan",
    insurance: "Included",
    sale_start_date: new Date(),
    sale_end_date: new Date(),
    posting_start_date: new Date(),
    posting_end_date: new Date(),
    car_classes: [
      {
        name: "Kクラス",
        price_items: [
          {
            id: 3,
            base_rate_period_group_name: "Base rate period group 2",
            prices: [
              { timeSlot: { type: "daily", value: "same_day" }, value: 4000 },
              { timeSlot: { type: "daily", value: "1_night_2_days" }, value: 7000 },
              { timeSlot: { type: "daily", value: "2_nights_3_days" }, value: 13000 },
              { timeSlot: { type: "daily", value: "3_night_4_days" }, value: 1500 },
              { timeSlot: { type: "daily", value: "daily_thereafter" }, value: 14000 }
            ]
          },
          {
            id: 4,
            base_rate_period_group_name: "Base rate period group 2",
            prices: [
              { timeSlot: { type: "hourly", value: "1_6_hours" }, value: 6000 },
              { timeSlot: { type: "hourly", value: "7_12_hours" }, value: 89000 },
              { timeSlot: { type: "hourly", value: "13_24_hours" }, value: 17000 },
              { timeSlot: { type: "hourly", value: "hourly_thereafter" }, value: 1200 },
              { timeSlot: { type: "hourly", value: "daily_thereafter" }, value: 15000 }
            ]
          }
        ]
      }
    ]
  },
  {
    status: false,
    ota_id: 1,
    name: "Jalan  plan",
    insurance: "Included",
    sale_start_date: new Date(2023, 5, 20),
    sale_end_date: new Date(2023, 6, 25),
    posting_start_date: new Date(),
    posting_end_date: new Date(),
    car_classes: [
      {
        name: "V1-Kクラス禁煙",
        price_items: [
          {
            id: 5,
            base_rate_period_group_name: "",
            prices: [
              { timeSlot: { type: "daily", value: "same_day" }, value: 4000 },
              { timeSlot: { type: "daily", value: "1_night_2_days" }, value: 7000 },
              { timeSlot: { type: "daily", value: "2_nights_3_days" }, value: 13000 },
              { timeSlot: { type: "daily", value: "3_night_4_days" }, value: 1500 },
              { timeSlot: { type: "daily", value: "daily_thereafter" }, value: 14000 }
            ]
          }
        ]
      }
    ]
  }
]
