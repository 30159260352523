export default [
  {
    id: 1,
    name: "基本料金期間グループの名前は最大で40文字です。この文章がちょうど40文字です。",
    periods: [
      {
        id: 1,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 2,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 3,
        start_date: new Date(),
        end_date: new Date()
      }
    ]
  },
  {
    id: 2,
    name: "2022年10月平日",
    periods: [
      {
        id: 4,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 5,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 6,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 7,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 8,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 9,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 10,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 11,
        start_date: new Date(),
        end_date: new Date()
      }
    ]
  },
  {
    id: 3,
    name: "2023年10月平日",
    periods: []
  },
  {
    id: 4,
    name: "2022年10月3連休",
    periods: [
      {
        id: 12,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 13,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 14,
        start_date: new Date(),
        end_date: new Date()
      },
      {
        id: 15,
        start_date: new Date(),
        end_date: new Date()
      }
    ]
  }
]
