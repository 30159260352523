export const defaultFiltersObject = () => ({
  searchValue: "",
  otas: [],
  shop: undefined,
  carClasses: [],
  status: undefined,
  dateRange: [],
  periodInclusion: undefined,
  insurance: undefined,
  timeSlotType: undefined,
  saleStatus: undefined
})
