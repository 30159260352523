import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"

import dummyItems from "./dummyData.js"
import { defaultFiltersObject } from "./helpers.js"

const BASE_URI = "/plan_management/plan_list"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: defaultFiltersObject()
})

store.mergeState({
  items: dummyItems
})

store.mergeActions(withLoading)
store.mergeMutations(extractMutations())

export default store
