import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import dummyItems from "./dummyData.js"

const BASE_URI = "/plan_management/base_rate_period_settings"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    searchValue: "",
    periodInclusion: undefined,
    dateRange: []
  }
})

store.mergeState({
  items: dummyItems
})

store.mergeActions(withLoading)

export default store
