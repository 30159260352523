<template lang="pug">
  .base-rate-period-settings
    TopBar(
      @add-new="handleAddNew"
      :filters="filters"
      @change-filters="setFilters"
    )
    PeriodSettingsTable(
      :period-setting-items="baseRatePeriodSettingItems"
      :period-setting-items-added="baseRatePeriodSettingItemsAdded"
      @add-new-period="handleAddNewPeriod"
      @remove-period="handleRemovePeriod"
    )
</template>

<script>
  import baseRatePeriodSettingsModule from "@/config/store/plan_management/base_rate_period_settings"
  import withStoreModule from "@/mixins/withStoreModule"

  const baseRatePeriodSettingsMixin = withStoreModule(baseRatePeriodSettingsModule, {
    name: "baseRatePeriodSettings",
    readers: {
      filters: "filters",
      baseRatePeriodSettingItems: "items",
      baseRatePeriodSettingItemsAdded: "itemsAdded"
    },
    mutations: {
      addItem: "ADD_ITEM",
      updateItemByIndex: "UPDATE_ITEM_BY_INDEX",
      updateAddedItemByIndex: "UPDATE_ADDED_ITEM_BY_INDEX",
      setFilters: "SET_FILTERS"
    }
  })

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      PeriodSettingsTable: () => import("./PeriodSettingsTable")
    },

    mixins: [baseRatePeriodSettingsMixin],

    methods: {
      handleAddNew() {
        this.addItem({ item: { name: "", periods: [] } })
      },

      handleAddNewPeriod(baseRatePeriodSettingItem) {
        baseRatePeriodSettingItem.periods.push({ start_date: null, end_date: null, _addedAt: Date.now() })
      },

      handleRemovePeriod(baseRatePeriodSettingItem, index) {
        baseRatePeriodSettingItem.periods.splice(index, 1)
      }
    }
  }
</script>

<style lang="sass" scoped></style>
